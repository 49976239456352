html, body, #root {
    min-width: 100%;
    min-height: 100%;
    height: 100%;
    background: #F3F3F3;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
    border-spacing: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Gilroy'
}

a {
    transition: 0.3s;
    cursor: pointer;
    text-decoration: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a:hover {
    filter: brightness(.8);
}

a:visited {
    color: unset;
}
